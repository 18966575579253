<template>
  <!-- HEADER -->
  <div class="bg-gray-100">
    <div class="max-w-7xl mx-auto py-8 px-4 sm:py-12 sm:px-6 lg:px-8">
      <div class="text-left">
        <!-- <h2 class="text-base font-semibold text-indigo-600 tracking-wide uppercase">Pricing</h2> -->
        <h2
          class="
            text-2xl
            font-extrabold
            tracking-tight
            text-red-600
            sm:text-4xl
          "
        >
          Ceny a dodací lhůty
        </h2>
        <p class="max-w-xl mt-2 mr-auto text-lg sm:text-xl text-gray-500">
          Ceník platný od 1/9/2024
        </p>
      </div>
    </div>
  </div>
  <!-- HEADER END -->

  <!-- TABLE CONTAINER -->

  <div class="max-w-7xl pt-14 mx-auto sm:px-6 lg:px-8">
    <!-- TABLE HEADER -->
    <div
      class="
        pb-5
        border-b border-gray-200
        sm:flex sm:items-center sm:justify-between
      "
    >
      <h3
        class="
          text-md
          sm:text-lg
          ml-3
          sm:ml-0
          leading-6
          font-medium
          text-gray-900
        "
      >
        Kompletní ceník zubních náhrad pro digitální zakázky
      </h3>
      <!-- BUTTONS -->
      <div class="mt-3 flex sm:mt-0 sm:ml-4">
        <div class="hidden relative flex-grow focus-within:z-10">
          <div
            class="
              absolute
              inset-y-0
              left-0
              pl-3
              flex
              items-center
              pointer-events-none
            "
          >
            <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="text"
            name="mobile-search-candidate"
            id="mobile-search-candidate"
            class="
              focus:ring-red-500 focus:border-red-500
              block
              w-full
              rounded-md
              pl-10
              sm:hidden
              border-gray-300
            "
            placeholder="Hledat"
          />
          <input
            type="text"
            name="desktop-search-candidate"
            id="desktop-search-candidate"
            class="
              hidden
              focus:ring-red-500 focus:border-red-500
              w-full
              rounded-md
              pl-10
              sm:block sm:text-sm
              border-gray-300
            "
            placeholder="Hledat"
          />
        </div>
        <a
          :href="`${publicPath}EZ_CZ_cenik_2024_Klasik.pdf`"
          download="EZ_CZ_cenik_2024_Klasik.pdf"
        >
          <button
            type="button"
            class="
              ml-3
              inline-flex
              items-center
              px-4
              py-2
              border border-transparent
              rounded-md
              shadow-sm
              text-sm
              font-medium
              whitespace-nowrap
              text-white
              bg-red-600
              hover:bg-red-700
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-red-500
            "
          >
            Stáhnout ceník zubních náhrad pro KLASICKÉ OTISKY (.pdf)
          </button>
        </a>
      </div>
      <!-- BUTTONS END -->
    </div>

    <!-- TABLE HEADER END -->
    <!-- Content goes here -->

    <!-- TABLE -->

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="
              shadow
              overflow-hidden
              border-b border-gray-200
              sm:rounded-lg
            "
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-1
                      sm:py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Kód
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-1
                      sm:py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Název
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-1
                      sm:py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    <div>Cena</div>
                    <div class="capitalize text-xs text-gray-400">Kč</div>
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-1
                      sm:py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    <div>Cena</div>
                    <div class="uppercase text-xs text-gray-400">EUR</div>
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-1
                      sm:py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    <div class="text-gray-500 min-w-max">Dodací lhůta</div>
                    <div class="text-xs text-gray-400 min-w-max">
                      Pracovní dny
                    </div>
                  </th>
                  <!-- <th scope="col" class="relative px-6 py-3">
                <span class="sr-only">Edit</span>
            </th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, itemIdx) in itemList"
                  :key="item.code"
                  :class="
                    itemIdx % 2 === 0
                      ? 'bg-white hover:bg-red-50'
                      : 'bg-gray-50 hover:bg-red-50'
                  "
                >
                  <td
                    v-if="item.type === 'category'"
                    colspan="5"
                    class="
                      px-3
                      py-3
                      whitespace-nowrap
                      text-md
                      font-semibold
                      text-red-600
                    "
                  >
                    {{ item.name }}
                  </td>
                  <td
                    v-if="item.type === 'item'"
                    class="px-6 py-3 whitespace-nowrap text-sm text-gray-500"
                  >
                    {{ item.code
                    }}<span v-if="item.codeAdd">,{{ item.codeAdd }}</span>
                  </td>
                  <td
                    v-if="item.type === 'item'"
                    class="
                      px-6
                      py-3
                      whitespace-nowrap
                      text-sm
                      font-medium
                      text-gray-900
                    "
                  >
                    <div class="text-sm text-gray-900">{{ item.name }}</div>
                    <div v-if="item.description" class="text-sm text-gray-500">
                      {{ item.description }}
                    </div>
                  </td>
                  <td
                    v-if="item.type === 'item'"
                    class="px-6 py-3 whitespace-nowrap text-sm text-gray-500"
                  >
                    {{ item.price }} Kč
                  </td>
                  <td
                    v-if="item.type === 'item'"
                    class="px-6 py-3 whitespace-nowrap text-sm text-gray-500"
                  >
                    <span v-if="item.priceEur">{{ item.priceEur }} €</span>
                  </td>
                  <td
                    v-if="item.type === 'item'"
                    class="px-6 py-3 whitespace-nowrap text-sm text-gray-500"
                  >
                    {{ item.leadTime }}
                  </td>
                  <!-- td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ item.role }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <a href="#" class="text-indigo-600 hover:text-indigo-900">Edit</a>
                </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- TABLE END -->
  </div>

  <!-- TABLE CONTAINER END -->
  <GalleryOverview />
</template>

<script>
import Papa from 'papaparse'
import GalleryOverview from '@/components/GalleryOverview.vue'
// import { ChevronDownIcon, SearchIcon, SortAscendingIcon } from '@heroicons/vue/solid'
import { SearchIcon } from '@heroicons/vue/solid'

// eslint-disable-next-line no-unused-vars
const itemListOld = [
  {
    id: 0,
    code: '0',
    type: 'category',
    category: 'phase',
    name: 'SAMOSTATNÉ VÝROBNÍ FÁZE',
    price: null,
    priceEur: null,
    leadTime: null,
  },
  {
    id: 1,
    code: '010111',
    type: 'item',
    category: 'phase',
    name: 'Skusová šablona (1 čelist), test plast',
    price: 359,
    priceEur: 15,
    leadTime: 2,
  },
  {
    id: 3,
    code: '120201',
    type: 'item',
    category: 'phase',
    name: 'Studijní model',
    price: 249,
    priceEur: 10,
    leadTime: 2,
  },
  {
    id: 4,
    code: '120207',
    type: 'item',
    category: 'phase',
    name: 'Individuální otiskovací lžíce',
    price: 349,
    priceEur: 14,
    leadTime: 2,
  },
  {
    id: 5,
    code: '120214',
    type: 'item',
    category: 'phase',
    name: 'Sponová modelace',
    price: 349,
    priceEur: 14,
    leadTime: null,
  },
  {
    id: 6,
    code: '120218',
    type: 'item',
    category: 'phase',
    name: 'Wax-up (1 člen)',
    price: 499,
    priceEur: 20,
    leadTime: 5,
  },
  {
    id: 7,
    code: '120217',
    type: 'item',
    category: 'phase',
    name: 'Silikonový klíč dle Wax-up',
    price: 399,
    priceEur: 16,
    leadTime: 2,
  },
  {
    id: 9,
    code: '1202332',
    type: 'item',
    category: 'phase',
    name: 'Kapna k úpravě preparace CoCr',
    price: 169,
    priceEur: 7,
    leadTime: 5,
  },
  {
    id: 10,
    code: '120401',
    type: 'item',
    category: 'phase',
    name: 'Individuální abutment CoCr',
    description: 'Titanová báze se připočítává',
    price: 1899,
    priceEur: 76,
    leadTime: null,
  },
  {
    id: 11,
    code: '120403',
    type: 'item',
    category: 'phase',
    name: 'Individuální abutment Zirkon',
    price: 1899,
    priceEur: 76,
    leadTime: null,
  },
  {
    id: 12,
    code: '120406',
    type: 'item',
    category: 'phase',
    name: 'Implantát – výběr, objednání, úprava, lepení',
    price: 649,
    priceEur: 26,
    leadTime: null,
  },
  {
    id: 13,
    code: '120413',
    type: 'item',
    category: 'phase',
    name: 'Růžová estetika (za člen)',
    price: 179,
    priceEur: 7,
    leadTime: null,
  },
  {
    id: 14,
    code: '80061',
    type: 'item',
    category: 'phase',
    name: 'Titanová baze - ASTRA, BICON, OSSTEM',
    price: 2999,
    priceEur: 120,
    leadTime: null,
  },
  {
    id: 15,
    code: '80062',
    type: 'item',
    category: 'phase',
    name: 'Titanová báze – interface',
    price: 1499,
    priceEur: 60,
    leadTime: null,
  },
  {
    id: 16,
    code: '80063',
    type: 'item',
    category: 'phase',
    name: 'Titanová báze - interface STRAUMANN',
    price: 2299,
    priceEur: 92,
    leadTime: null,
  },
  {
    id: 16,
    code: '80064',
    type: 'item',
    category: 'phase',
    name: 'Individuální abutment titanový',
    price: 1999,
    priceEur: 80,
    leadTime: null,
  },
  {
    id: 17,
    code: '80071',
    type: 'item',
    category: 'phase',
    name: 'Zhotovení Zirkon/CoCr korunky pod protézu – příplatek',
    price: 499,
    priceEur: 20,
    leadTime: 5,
  },
  {
    id: 18,
    code: '80072',
    type: 'item',
    category: 'phase',
    name: 'Kořenová nástavba do stávající korunky – příplatek',
    price: 299,
    priceEur: 12,
    leadTime: 5,
  },
  {
    id: 19,
    code: '80090',
    type: 'item',
    category: 'phase',
    name: 'Individuální výběr barvy v laboratoři',
    price: 199,
    priceEur: 8,
    leadTime: null,
  },
  {
    id: 19,
    code: '81138',
    type: 'item',
    category: 'phase',
    name: 'Ti-třmen (za implantát)',
    price: 3499,
    priceEur: 145,
    leadTime: null,
  },
  {
    id: 20,
    code: '81139',
    type: 'item',
    category: 'phase',
    name: 'Kovová CoCr výztuž (1 člen)',
    price: 250,
    priceEur: 10,
    leadTime: 5,
  },
  {
    id: 21,
    code: '81140',
    type: 'item',
    category: 'phase',
    name: 'PEEK konstrukce (1 člen)',
    price: 499,
    priceEur: 20,
    leadTime: 5,
  },
  {
    id: 22,
    code: '81141',
    type: 'item',
    category: 'phase',
    name: 'Express příplatek',
    price: 500,
    priceEur: 20,
    leadTime: null,
  },
  {
    id: 23,
    code: '81142',
    type: 'item',
    category: 'phase',
    name: 'Vícepráce (10 minut)',
    price: 150,
    priceEur: 6,
    leadTime: null,
  },
  {
    id: 24,
    code: '1',
    type: 'category',
    category: 'remodel',
    name: 'REKONSTRUKCE KORUNKY CAD/CAM',
    price: null,
    priceEur: null,
    leadTime: null,
  },
  {
    id: 25,
    code: '120317',
    type: 'item',
    category: 'remodel',
    name: 'Inlay, onlay – Zirkon',
    price: 1599,
    priceEur: 64,
    leadTime: 5,
  },
  {
    id: 26,
    code: '1203171',
    type: 'item',
    category: 'remodel',
    name: 'Inlay, onlay – PMMA',
    price: 899,
    priceEur: 36,
    leadTime: 5,
  },
  {
    id: 28,
    code: '020111',
    type: 'item',
    category: 'remodel',
    name: 'Inlay kořenová, přímá CoCr',
    price: 799,
    priceEur: 32,
    leadTime: 5,
  },
  {
    id: 29,
    code: '020112',
    type: 'item',
    category: 'remodel',
    name: 'Inlay kořenová, nepřímá CoCr',
    price: 999,
    priceEur: 40,
    leadTime: 5,
  },
  {
    id: 30,
    code: '2',
    type: 'category',
    category: 'composite',
    name: 'SIGNUM CERAMIS (Kulzer) KOMPOZITNÍ INDIVIDUÁLNĚ VRSTVENÉ (RTG kontrastní)',
    price: null,
    priceEur: null,
    leadTime: null,
  },
  {
    id: 31,
    code: '120316',
    type: 'item',
    category: 'composite',
    name: 'Inlay, onlay – estetická',
    price: 1899,
    priceEur: 76,
    leadTime: 7,
  },
  {
    id: 32,
    code: '120319',
    type: 'item',
    category: 'composite',
    name: 'Overlay, polokorunka – estetická',
    price: 2299,
    priceEur: 90,
    leadTime: 7,
  },
  {
    id: 320,
    code: "120320",
    type: "item",
    category: "composite",
    name: "KORUNKA - ESTETICKÁ",
    price: 2599,
    priceEur: 100,
    leadTime: 7,
  },
  {
    id: 33,
    code: '3',
    type: 'category',
    category: 'replacement',
    name: 'KORUNKOVÉ A PILÍŘOVÉ NÁHRADY, SPOJENÍ NÁHRAD CAD/CAM',
    price: null,
    priceEur: null,
    leadTime: null,
  },
  {
    id: 34,
    code: '030111',
    type: 'item',
    category: 'replacement',
    name: 'Korunka plášťová CrCo',
    price: 1299,
    priceEur: 50,
    leadTime: 5,
  },
  {
    id: 34,
    code: '030311',
    type: 'item',
    category: 'replacement',
    name: 'Korunka plášťová kompozitní (Bredent)',
    price: 2299,
    priceEur: 92,
    leadTime: 5,
  },
  {
    id: 35,
    code: '0303112',
    type: 'item',
    category: 'replacement',
    name: 'Korunka plášťová PMMA (multicolor)',
    price: 1399,
    priceEur: 50,
    leadTime: 5,
  },
  {
    id: 36,
    code: '030512',
    type: 'item',
    category: 'replacement',
    name: 'Korunka celokeramická – Zirkon – fasetovaná',
    price: 2599,
    priceEur: 100,
    leadTime: 7,
  },
  {
    id: 37,
    code: '0305121',
    type: 'item',
    category: 'replacement',
    name: 'Korunka celokeramická – Zirkon – vest. fasetovaná',
    price: 2399,
    priceEur: 95,
    leadTime: 7,
  },
  {
    id: 38,
    code: '030511',
    type: 'item',
    category: 'replacement',
    name: 'Korunka celokeramická – Zirkon – anatomická',
    price: 2299,
    priceEur: 92,
    leadTime: 5,
  },
  {
    id: 39,
    code: '030514',
    type: 'item',
    category: 'replacement',
    name: 'Korunka celokeramická E-max – fasetovaná',
    price: 3499,
    priceEur: 150,
    leadTime: 7,
  },
  {
    id: 40,
    code: '030513',
    type: 'item',
    category: 'replacement',
    name: 'Korunka celokeramická E-max – monolitická',
    price: 2899,
    priceEur: 120,
    leadTime: 7,
  },
  {
    id: 41,
    code: '0306111',
    type: 'item',
    category: 'replacement',
    name: 'Korunka vestibulárně fasetovaná – keramika',
    price: 2299,
    priceEur: 92,
    leadTime: 7,
  },
  {
    id: 42,
    code: '030611',
    type: 'item',
    category: 'replacement',
    name: 'Korunka fasetovaná – metalokeramika',
    price: 2499,
    priceEur: 98,
    leadTime: 7,
  },
  {
    id: 44,
    code: '120601',
    type: 'item',
    category: 'replacement',
    name: 'Inlay jako pilíř můstku – leštěný kov',
    price: 1099,
    priceEur: 45,
    leadTime: 7,
  },
  {
    id: 45,
    code: '1206011',
    type: 'item',
    category: 'replacement',
    name: 'Inlay jako pilíř můstku – fasetovaná keramikou',
    price: 1899,
    priceEur: 76,
    leadTime: 7,
  },
  {
    id: 54,
    code: '6',
    type: 'category',
    category: 'adhesive',
    name: 'ADHESIVNÍ NÁHRADY CAD/CAM',
    price: null,
    priceEur: null,
    leadTime: null,
  },
  {
    id: 55,
    code: '120901',
    type: 'item',
    category: 'adhesive',
    name: 'Adhezivní můstek PEEK (jeden mezičlen - kompozit)',
    price: 3990,
    priceEur: 170,
    leadTime: 7,
  },
  {
    id: 57,
    code: '120902',
    type: 'item',
    category: 'adhesive',
    name: 'Adhesivní můstek (jeden mezičlen – keramika)',
    price: 2799,
    priceEur: 120,
    leadTime: 7,
  },
  {
    id: 59,
    code: '120904',
    type: 'item',
    category: 'adhesive',
    name: 'Stabilizační dlaha - Retainer',
    price: 500,
    priceEur: 20,
    leadTime: 5,
  },
  {
    id: 60,
    code: '120906',
    type: 'item',
    category: 'adhesive',
    name: 'Adhezivní můstek e.max (1 člen)',
    price: 2999,
    priceEur: 120,
    leadTime: 7,
  },
  {
    id: 61,
    code: '7',
    type: 'category',
    category: 'temporal',
    name: 'PROVIZORNÍ FIXNÍ NÁHRADY CAD/CAM',
    price: null,
    priceEur: null,
    leadTime: null,
  },
  {
    id: 62,
    code: '030711',
    type: 'item',
    category: 'temporal',
    name: 'Provizorní korunka / mezičlen',
    price: 980,
    priceEur: 48,
    leadTime: 2,
  },
  {
    id: 64,
    code: '8',
    type: 'category',
    category: 'misc',
    name: 'DLAHY, FÓLIE, CHRÁNIČE ZUBŮ',
    price: null,
    priceEur: null,
    leadTime: null,
  },
  {
    id: 65,
    code: '121204',
    type: 'item',
    category: 'misc',
    name: 'Nákusná dlaha – bruxismus',
    price: 1199,
    priceEur: 50,
    leadTime: 5,
  },
  {
    id: 65,
    code: '121208',
    type: 'item',
    category: 'misc',
    name: 'Dlaha s náhradou zubu (provizorium)',
    price: 1499,
    priceEur: 60,
    leadTime: 5,
  },
  {
    id: 66,
    code: '121202',
    type: 'item',
    category: 'misc',
    name: 'Fólie k bělení',
    price: 999,
    priceEur: 40,
    leadTime: 5,
  },
  {
    id: 67,
    code: '121201',
    type: 'item',
    category: 'misc',
    name: 'Chránič zubů',
    price: 1820,
    priceEur: 60,
    leadTime: 5,
  },
]

export default {
  components: {
    SearchIcon,
    GalleryOverview,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      itemList: [],
    }
  },
  methods: {
    loadCSV() {
      const csvFilePath = `${this.publicPath}pricelist_2024.csv`

      // Fetch the CSV file
      fetch(csvFilePath)
        .then((response) => response.text())
        .then((data) => {
          // Parse the CSV data into JSON
          Papa.parse(data, {
            header: true,
            dynamicTyping: true,
            complete: (result) => {
              this.itemList = result.data
            }
          })
        })
        .catch((error) => {
          console.error('Error loading CSV file:', error)
        })
    }
  },
  created() {
    this.loadCSV()
  },
}
</script>
