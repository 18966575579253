<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <main>
    <div>
      <!-- Hero card -->
      <div class="relative">
        <div class="absolute inset-x-0 bottom-0 h-1/2 bg-white" />
        <div class="max-w-7xl mx-auto">
          <div class="relative shadow-xl sm:overflow-hidden">
            <div class="absolute inset-0">
              <img
                class="h-full w-full object-cover"
                src="@/assets/img/components/hero.jpg"
                alt="People working on laptops"
              />
              <div
                class="
                  absolute
                  inset-x-0 inset-y-8
                  max-w-sm
                  sm:inset-x-4 sm:inset-y-20 sm:max-w-2xl
                  md:max-w-3xl
                  bg-red-600
                  mix-blend-multiply
                "
              />
            </div>
            <!-- OVERLAY -->
            <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <!-- <div class="max-w-max bg-red-600 py-6 px-6 mix-blend-multiply"> -->
              <h1
                class="
                  text-left text-lg
                  font-medium
                  tracking-tight
                  sm:text-5xl
                  lg:text-6xl
                "
              >
                <span class="block text-white">Partner pro Vaši digitální</span>
                <span class="block text-white">stomatologickou praxi</span>
              </h1>
              <p
                class="
                  mt-6
                  font-light
                  max-w-lg
                  mx-left
                  text-left text-lg text-white
                  sm:max-w-3xl
                "
              >
                S Vaším IO skenerem si rozumíme:
              </p>

              <div
                class="
                  sm:mt-10
                  mt-4
                  max-w-sm
                  sm:max-w-none sm:flex sm:justify-center
                "
              >
                <div
                  class="
                    sm:mr-auto
                    mr-auto
                    flex
                    sm:flex-nowrap
                    flex-wrap
                    items-baseline
                    sm:items-center
                  "
                >
                  <img
                    class="
                      max-h-5
                      md:h-5
                      sm:h-3
                      h-4
                      mx-2
                      sm:mb-0
                      mb-3
                      object-contain
                    "
                    src="@/assets/graphics/medit.png"
                    alt=""
                  />
                  <img
                    class="
                      max-h-5
                      md:h-5
                      sm:h-8
                      h-8
                      mx-2
                      sm:mb-0
                      mb-3
                      object-contain
                    "
                    src="@/assets/graphics/3shape_sm.png"
                    alt=""
                  />
                  <img
                    class="
                      max-h-5
                      md:h-5
                      sm:h-3
                      h-4
                      mx-2
                      sm:mb-0
                      mb-3
                      object-contain
                    "
                    src="@/assets/graphics/itero.png"
                    alt=""
                  />
                  <img
                    class="
                      max-h-5
                      md:h-5
                      sm:h-3
                      h-4
                      mx-2
                      sm:mb-0
                      mb-3
                      object-contain
                    "
                    src="@/assets/graphics/carestream.png"
                    alt=""
                  />
                  <img
                    class="
                      max-h-5
                      md:h-5
                      sm:h-3
                      h-4
                      mx-2
                      sm:mb-0
                      mb-3
                      object-contain
                    "
                    src="@/assets/graphics/sirona.png"
                    alt=""
                  />
                  <img
                    class="
                      max-h-5
                      md:h-5
                      sm:h-3
                      h-4
                      mx-2
                      sm:mb-0
                      mb-3
                      object-contain
                    "
                    src="@/assets/graphics/newtom.png"
                    alt=""
                  />
                  <img
                    class="
                      max-h-8
                      md:h-8
                      -mt-1
                      sm:h-6
                      h-3
                      w-20
                      mx-2
                      sm:mb-0
                      mb-3
                      object-contain
                    "
                    src="@/assets/graphics/shining3d.png"
                    alt=""
                  />
                </div>
              </div>
              <!-- </div> -->
            </div>
            <!-- OVERLAY END -->
          </div>
        </div>
      </div>
    </div>

    <!-- More main page content here... -->
  </main>
  <Banner />
  <!-- -->
  <div class="bg-white">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:pb-8 sm:px-6 lg:px-8">
      <div class="text-center">
        <p
          class="
            mt-1
            text-xl
            font-extrabold
            text-red-600
            sm:text-5xl sm:tracking-tight
            lg:text-6xl
          "
        >
          14 let zkušeností v digitální stomatologii
        </p>
        <p class="max-w-xl mt-5 mx-auto text-2xl text-gray-500">
          Přes 81 000 zpracovaných skenů
        </p>
      </div>
    </div>
  </div>
  <!--  -->

  <!-- LEADTIMES SECTION -->

  <LeadTimes />

  <!-- LEADTIMES SECTION END -->

  <div class="bg-white">
    <div class="pt-12 sm:pt-14 lg:pt-20">
      <div class="max-w-7xl mx-auto text-left px-4 sm:px-6 lg:px-8">
        <div class="max-w-3xl mx-auto space-y-2 lg:max-w-none">
          <h2
            class="
              pl-2
              border-l-2 border-red-600
              text-lg
              leading-6
              font-semibold
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            PODKLADY K ZAKÁZCE
          </h2>
          <p
            class="text-3xl font-extrabold text-red-600 sm:text-4xl lg:text-5xl"
          >
            Zpracujeme otisky i data ze skeneru
          </p>
          <p class="text-xl text-gray-500">
            Poslat nám můžete jak digitální, tak klasické — zjistěte, čím je náš
            výrobní proces unikátní
          </p>
        </div>
      </div>
    </div>
    <div class="mt-8 pb-12 bg-white sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
      <div class="relative">
        <div class="absolute inset-0 h-3/4 bg-white" />
        <div class="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div
            class="
              max-w-md
              mx-auto
              space-y-4
              lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0
            "
          >
            <div
              v-for="tier in tiers"
              :key="tier.name"
              class="flex flex-col rounded-lg shadow-lg overflow-hidden"
            >
              <div class="text-center bg-white">
                <!-- <img src="@/assets/img/components/impressions.jpg" alt=""> -->

                <div class="relative bg-gray-800">
                  <div class="absolute inset-0">
                    <img
                      class="w-full h-full object-cover"
                      :src="tier.imageUrl"
                      alt=""
                    />
                    <!-- <div class="absolute inset-0 bg-gray-800 mix-blend-multiply" aria-hidden="true" /> -->
                  </div>
                  <div
                    class="
                      relative
                      max-w-5
                      mx-auto
                      py-20
                      px-4
                      sm:py-22 sm:px-6
                      lg:px-8
                    "
                  >
                    <p class="mt-5 text-3xl text-red-600">
                      {{ tier.description }}
                    </p>
                    <div
                      class="
                        mt-4
                        items-baseline
                        text-6xl
                        font-extrabold
                        text-red-600
                      "
                    >
                      {{ tier.priceMonthly }}
                    </div>
                  </div>
                </div>

                <!-- <div>
                  <h3 class="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600" id="tier-standard">
                    {{ tier.name }}
                  </h3>
                </div> -->
                <!-- <p class="mt-5 text-lg text-red-600">
                  {{ tier.description }}
                </p>
                <div class="mt-4 items-baseline text-6xl font-extrabold text-red-600">
                  {{ tier.priceMonthly }}
                </div> -->
              </div>
              <div
                class="
                  flex-1 flex flex-col
                  justify-between
                  px-6
                  pt-6
                  pb-8
                  bg-gray-50
                  space-y-6
                  sm:p-10 sm:pt-6
                "
              >
                <ul role="list" class="space-y-4">
                  <li
                    v-for="feature in tier.features"
                    :key="feature"
                    class="flex items-start"
                  >
                    <div class="flex-shrink-0">
                      <CheckIcon
                        class="h-6 w-6 text-green-500"
                        aria-hidden="true"
                      />
                    </div>
                    <p class="ml-3 text-base text-gray-700">
                      {{ feature }}
                    </p>
                  </li>
                </ul>
                <!-- SECONDARY BUTTON SECTION -->
                <div v-if="tier.buttonSecondary">
                  <div class="relative mb-2 flex justify-center">
                    <span class="px-2 bg-gray-50 text-sm text-gray-500">
                      Po-Pá 8:00 - 17:00
                    </span>
                  </div>
                  <div class="group rounded-md shadow">
                    <a
                      :href="tier.hrefSecondary"
                      class="
                        uppercase
                        flex
                        items-center
                        justify-center
                        px-5
                        py-3
                        border border-transparent
                        text-base
                        font-medium
                        rounded-md
                        text-red-600
                        bg-white
                        hover:bg-gray-50
                        group-hover:text-red-600
                        hover:border-red-600
                      "
                      aria-describedby="tier-standard"
                    >
                      <PhoneIcon
                        class="
                          mr-2
                          ml-2
                          h-6
                          w-6
                          text-red-600
                          group-hover:text-red-600
                        "
                        aria-hidden="true"
                      />
                      {{ tier.buttonSecondary }}
                    </a>
                  </div>
                  <div class="mt-4 relative">
                    <div
                      class="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div class="w-full border-t border-gray-300" />
                    </div>
                    <div class="relative flex justify-center">
                      <span class="px-2 bg-gray-50 text-sm text-gray-500">
                        nebo
                      </span>
                    </div>
                  </div>
                </div>
                <!-- SECONDARY BUTTON SECTION END -->
                <div class="group rounded-md shadow">
                  <a
                    :href="tier.href"
                    class="
                      uppercase
                      flex
                      items-center
                      justify-center
                      px-5
                      py-3
                      border border-transparent
                      text-base
                      font-medium
                      rounded-md
                      text-white
                      bg-red-600
                      hover:bg-white
                      group-hover:text-red-600
                      hover:border-red-600
                    "
                    aria-describedby="tier-standard"
                  >
                    {{ tier.button }}
                    <ExternalLinkIcon
                      class="
                        -mr-1
                        ml-3
                        h-5
                        w-5
                        text-gray-100
                        group-hover:text-red-600
                      "
                      aria-hidden="true"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
        <div class="max-w-md mx-auto lg:max-w-5xl">
          <div
            class="
              rounded-lg
              bg-gray-100
              px-6
              py-8
              sm:p-10
              lg:flex lg:items-center
            "
          >
            <div class="flex-1">
              <div>
                <h3
                  class="
                    inline-flex
                    px-4
                    py-1
                    rounded-full
                    text-sm
                    font-semibold
                    tracking-wide
                    uppercase
                    bg-white
                    text-gray-800
                  "
                >
                  <StarIcon
                    class="mr-2 h-5 w-5 text-gray-800"
                    aria-hidden="true"
                  />
                  náš tip
                </h3>
              </div>
              <div class="mt-4 text-lg text-gray-600">
                Staňte se produktivnější a ušetřete si další návštěvy pacientů.
                Prostě jednou naskenujte, pošlete data a čekejte na práci.
                <br />
                Poradíme Vám, jak můžete jednoduše zdokonalit digitální
                otiskování.
              </div>
            </div>
            <div
              class="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0"
            >
              <router-link
                :to="{ name: 'DigitalniOtiskovani' }"
                class="
                  flex
                  uppercase
                  items-center
                  justify-center
                  px-5
                  py-3
                  border border-transparent
                  text-base
                  font-medium
                  rounded-md
                  text-gray-900
                  bg-white
                  hover:bg-gray-50
                "
              >
                Jak lépe skenovat
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  -->

  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="bg-gray-50 overflow-hidden">
    <div class="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <!-- <svg class="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
    <defs>
        <pattern id="8b1b5f72-e944-4457-af67-0c6d15a99f38" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
        <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
        </pattern>
    </defs>
    <rect width="404" height="784" fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)" />
    </svg> -->

      <div class="lg:col-span-1 space-y-2">
        <h2
          class="
            text-lg
            pl-2
            border-l-2 border-red-600
            leading-6
            font-semibold
            text-gray-500
            uppercase
            tracking-wider
          "
        >
          PRODUKTY
        </h2>
        <p class="text-3xl font-extrabold text-red-600 sm:text-4xl lg:text-5xl">
          Precizní fixní náhrady
        </p>
        <p class="text-xl text-gray-500">
          Vyzkoušejte plně digitální CAD/CAM výrobu
        </p>
      </div>
      <div class="relative lg:grid lg:grid-cols-4 lg:gap-x-8">
        <dl
          class="
            my-10
            space-y-10
            sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10
            lg:my-10 lg:grid-cols-4 lg:col-span-4
          "
        >
          <div v-for="feature in features" :key="feature.name">
            <dt>
              <!-- <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
            <component :is="feature.icon" class="h-6 w-6" aria-hidden="true" />
            </div> -->
              <p
                class="
                  mt-5
                  uppercase
                  text-lg
                  leading-6
                  font-medium
                  text-red-600
                "
              >
                {{ feature.name }}
              </p>
            </dt>
            <dd class="mt-2 text-base text-gray-500">
              <div v-for="item in feature.description" :key="item">
                {{ item }}
              </div>
              <div v-if="feature.categoryRoute" class="my-6">
                <router-link
                  :to="feature.categoryRoute"
                  class="
                    flex
                    uppercase
                    items-left
                    justify-center
                    py-3
                    border border-transparent
                    text-base
                    font-medium
                    text-gray-900
                    bg-white
                    hover:bg-red-600 hover:text-gray-50
                  "
                >
                  {{ feature.button }}
                </router-link>
                <!-- <router-link :to="{ name: 'indikace' }" class="uppercase mt-8 bg-white border border-transparent shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-500 bg-white hover:text-gray-100 hover:bg-red-600">Více o indikacích</router-link> -->
              </div>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
  <!-- END -->

  <!--  -->
  <!-- <div class="bg-white">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <div class="bg-indigo-700 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
        <div class="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
          <div class="lg:self-center">
            <h2 class="text-3xl font-extrabold text-white sm:text-4xl">
              <span class="block">Ready to dive in?</span>
              <span class="block">Start your free trial today.</span>
            </h2>
            <p class="mt-4 text-lg leading-6 text-indigo-200">Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla nec.</p>
            <a href="#" class="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-indigo-600 hover:bg-indigo-50">Sign up for free</a>
          </div>
        </div>
        <div class="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
          <img class="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20" src="https://tailwindui.com/img/component-images/full-width-with-sidebar.jpg" alt="App screenshot" />
        </div>
      </div>
    </div>
  </div> -->
  <!-- -->

  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="relative bg-gray-900">
    <div class="h-80 w-full absolute bottom-0 xl:inset-0 xl:h-full">
      <div class="h-full w-full xl:grid xl:grid-cols-2">
        <div class="h-full xl:relative xl:col-start-2">
          <!-- <img class="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0" src="@/assets/img/components/drill.jpg" alt="People working on laptops" /> -->
          <video
            playsinline
            autoplay
            muted
            loop
            poster="@/assets/img/components/drill.jpg"
            id="bgvideo"
            class="h-full w-full object-cover opacity-75 xl:absolute xl:inset-0"
          >
            <source
              :src="require('@/assets/video/milling.mp4')"
              type="video/mp4"
            />
          </video>
          <div
            aria-hidden="true"
            class="
              absolute
              inset-x-0
              top-0
              h-32
              bg-gradient-to-b
              from-gray-900
              xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r
            "
          />
        </div>
      </div>
    </div>
    <div
      class="
        max-w-4xl
        mx-auto
        px-4
        sm:px-6
        lg:max-w-7xl lg:px-8
        xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8
      "
    >
      <div
        class="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24"
      >
        <h2 class="text-sm font-semibold text-red-600 tracking-wide uppercase">
          Moderní výroba a inovace
        </h2>
        <p class="mt-3 text-3xl font-extrabold text-white">
          Kvalita? Vždy vysoká!
        </p>
        <p class="mt-5 text-lg text-gray-300">
          Inovativní výroba pomocí 3D tisku, frézování a laserového sintrování
          Vám zaručuje vysokou a stálou ůroveň jakosti.
        </p>
        <router-link
          :to="{ name: 'Vyroba' }"
          class="
            uppercase
            mt-8
            bg-white
            border border-transparent
            shadow
            px-5
            py-3
            inline-flex
            items-center
            text-base
            font-medium
            text-gray-500
            bg-white
            hover:text-gray-100 hover:bg-red-600
          "
          >Více o výrobě</router-link
        >
      </div>
    </div>
  </div>
  <!-- -->

  <!-- MATERIALS -->
  <div class="bg-white py-16 lg:py-24">
    <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div
        class="
          relative
          py-24
          px-8
          shadow-2xl
          overflow-hidden
          lg:px-16 lg:grid lg:grid-cols-2 lg:gap-x-8
        "
      >
        <div class="absolute inset-0">
          <img
            src="@/assets/img/components/materials-gradient.jpg"
            alt=""
            class="w-full h-full object-cover"
          />
        </div>
        <div class="relative lg:col-span-1">
          <p class="mt-3 text-3xl font-extrabold text-red-600">
            Špičkové materiály
          </p>
          <p class="mt-5 text-lg text-gray-300">
            Správná indikace materiálu je stejně důležitá jako jeho kvalita.
            Spolehnětě se na naše dlouholeté zkušenosti, vždy Vám poradíme jaký
            materiál je pro Váš konkrétní případ nejvhodnější.
          </p>
          <div>
            <router-link
              :to="{ name: 'Materialy' }"
              class="
                uppercase
                mt-8
                bg-white
                border border-transparent
                shadow
                px-5
                py-3
                inline-flex
                items-center
                text-base
                font-medium
                text-gray-500
                bg-gray-100
                hover:text-gray-100 hover:bg-red-600
              "
              >Více o materiálech</router-link
            >
            <router-link
              :to="{ name: 'ObchodniPodminky' }"
              class="
                underline
                mt-8
                border border-transparent
                shadow
                px-5
                py-3
                inline-flex
                items-center
                text-base
                font-medium
                text-gray-100
                hover:text-red-600
              "
              >Obchodní a záruční podmínky</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MATERIALS END -->

  <!-- MISSION -->
  <div class="relative bg-white">
    <div
      class="
        h-56
        bg-indigo-600
        sm:h-72
        md:absolute md:left-0 md:h-full md:w-1/2
      "
    >
      <img
        class="w-full h-full object-cover"
        src="@/assets/img/components/care.jpg"
        alt=""
      />
    </div>
    <div class="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
      <div class="md:ml-auto md:w-1/2 md:pl-10">
        <!-- <h2 class="text-base font-semibold uppercase tracking-wider text-gray-300">
          Award winning support
        </h2> -->
        <p
          class="
            mt-2
            text-red-600 text-3xl
            font-extrabold
            tracking-tight
            sm:text-4xl
          "
        >
          Zkušenost a pečlivost
        </p>
        <p class="mt-3 text-lg text-gray-500">
          Za každým zubem chceme vidět spokojeného pacienta, dbáme proto na
          každý detail.
        </p>
        <div class="mt-8">
          <div class="inline-flex">
            <router-link
              :to="{ name: 'NasTeam' }"
              class="
                uppercase
                mt-8
                shadow
                bg-white
                border border-transparent
                shadow
                px-5
                py-3
                inline-flex
                items-center
                text-base
                font-medium
                text-gray-500
                bg-gray-100
                hover:text-gray-100 hover:bg-red-600
              "
            >
              Náš team
              <!-- <ExternalLinkIcon class="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" /> -->
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MISSION END -->

  <!-- SUPPORT DESKTOP -->

  <!-- SUPPORT TABS -->
  <TabGroup vertical :defaultIndex="1">
    <div class="hidden lg:flex bg-white mb-20">
      <div class="relative max-w-7xl mx-auto w-full">
        <div class="absolute left-4 h-full w-1/2">
          <div class="pl-6 py-6">
            <div class="max-w-3xl ml-0 flex flex-row">
              <StarIcon class="mr-2 h-5 w-5 text-red-600" aria-hidden="true" />
              <StarIcon class="mr-2 h-5 w-5 text-red-600" aria-hidden="true" />
              <StarIcon class="mr-2 h-5 w-5 text-red-600" aria-hidden="true" />
              <StarIcon class="mr-2 h-5 w-5 text-red-600" aria-hidden="true" />
              <StarIcon class="mr-2 h-5 w-5 text-red-600" aria-hidden="true" />
            </div>
            <h2
              class="text-left text-3xl font-extrabold text-red-600 sm:text-4xl"
            >
              Zákaznická podpora
            </h2>
          </div>
          <TabList as="ul" role="list" class="space-y-3">
            <Tab
              v-slot="{ selected }"
              as="li"
              v-for="item in support"
              :key="item.id"
            >
              <!-- Your content -->
              <div
                :class="[selected ? 'border-4 rounded-md border-red-600' : '']"
                class="
                  relative
                  ring-0
                  focus:ring-0
                  cursor-pointer
                  shadow-md
                  h-auto
                  w-10/12
                  bg-white
                  overflow-hidden
                  pt-8
                  z-30
                  rounded-md
                  border-gray-50 border-4
                  hover:border-red-600
                "
              >
                <div class="px-6 h-20">
                  <!-- <div class="relative z-10">
              <h3 class="mt-3 text-2xl font-extrabold text-red-600"> {{ item.category }} </h3>
              <p class="text-black"> {{ item.snippet }} </p>
            </div> -->
                </div>
                <div class="absolute inset-0 h-auto z-0">
                  <img
                    class="object-cover object-bottom cursor-pointer"
                    :src="item.imagePrimaryURL"
                    alt=""
                  />
                </div>
                <!-- ADD ROUTER LINK -->
                <!-- <router-link :to="item.categoryRoute"> -->
                <div class="relative bg-gray-50 z-20">
                  <div class="relative z-10 pt-1 pb-3 px-3">
                    <h3 class="mt-3 text-2xl font-extrabold text-red-600">
                      {{ item.category }}
                    </h3>
                    <!-- <p class="text-black"> {{ item.snippet }} </p> -->
                  </div>
                </div>
                <!-- </router-link> -->
              </div>
            </Tab>
          </TabList>
        </div>

        <!-- TABS CONTENT SECTION -->

        <TabPanels>
          <!-- TAB PANEL ITERATING -->
          <TabPanel v-for="item in support" :key="item.id">
            <div
              class="
                h-56
                bg-white
                sm:h-72
                lg:absolute lg:right-0 lg:h-full lg:w-1/2
              "
            >
              <img
                class="w-full h-full object-cover"
                :src="item.imageSecondaryURL"
                alt="Support team"
              />
            </div>
            <div
              style="height: 950px"
              class="
                relative
                max-w-7xl
                mx-auto
                px-4
                py-8
                sm:py-12 sm:px-6
                lg:py-16 lg:pt-36
                min-w-full
              "
            >
              <div
                class="
                  max-w-2xl
                  mx-auto
                  lg:max-w-none lg:mr-0 lg:ml-auto lg:w-1/2 lg:pl-10
                "
              >
                <h3
                  class="
                    text-sm
                    font-semibold
                    text-gray-600
                    tracking-wide
                    uppercase
                  "
                >
                  {{ item.category }}
                </h3>
                <h2
                  class="mt-6 text-3xl font-extrabold text-red-600 sm:text-4xl"
                >
                  {{ item.header }}
                </h2>
                <p
                  v-for="line in item.content"
                  :key="line.id"
                  class="mt-6 mb-6 pr-36 text-lg text-gray-500"
                >
                  {{ line }}
                  <!-- {{ item.content }} -->
                  <!-- <ul>
                <li v-for="line in item.content" :key="line.id">
                  {{ line }}
                  </li>
              </ul> -->
                  <!-- {{ item.contentSecondary }} -->
                </p>
                <ul v-if="item.tags" class="flex flex-col ml-4 mb-20">
                  <li
                    v-if="item.tagOne"
                    class="
                      inline-flex
                      items-center
                      mt-4
                      uppercase
                      text-sm
                      font-bold
                      text-gray-500
                    "
                  >
                    <FastForwardIcon
                      class="mr-2 h-8 w-8 text-red-600"
                      aria-hidden="true"
                    />
                    {{ item.tagOne }}
                  </li>
                  <li
                    v-if="item.tagTwo"
                    class="
                      inline-flex
                      items-center
                      mt-4
                      uppercase
                      text-sm
                      font-bold
                      text-gray-500
                    "
                  >
                    <RefreshIcon
                      class="mr-2 h-8 w-8 text-red-600"
                      aria-hidden="true"
                    />
                    {{ item.tagTwo }}
                  </li>
                  <li
                    v-if="item.tagThree"
                    class="
                      inline-flex
                      items-center
                      mt-4
                      uppercase
                      text-sm
                      font-bold
                      text-gray-500
                    "
                  >
                    <HeartIcon
                      class="mr-2 h-8 w-8 text-red-600"
                      aria-hidden="true"
                    />
                    {{ item.tagThree }}
                  </li>
                </ul>
                <div class="mt-8 overflow-hidden">
                  <h4
                    class="
                      text-sm
                      font-semibold
                      text-gray-600
                      tracking-wide
                      uppercase
                    "
                  >
                    {{ item.buttonHeading }}
                  </h4>
                </div>
                <div
                  v-if="item.buttonPrimaryURL"
                  class="mt-6 mb-20 rounded-md shadow"
                >
                  <router-link
                    :to="item.categoryRoute"
                    class="
                      flex
                      uppercase
                      items-left
                      justify-center
                      py-3
                      border border-transparent
                      text-base
                      font-medium
                      rounded-md
                      text-gray-900
                      bg-white
                      hover:bg-gray-50
                    "
                  >
                    {{ item.buttonPrimary }}
                  </router-link>
                </div>
                <!-- <div v-else class="mt-6 mb-20 py-3"></div> -->
              </div>
            </div>
          </TabPanel>
          <!-- TAB PANEL ITERATING END -->
        </TabPanels>
        <!-- TABS CONTENT SECTION END -->
      </div>
    </div>
  </TabGroup>

  <!-- SUPPORT TABS END -->

  <!-- OLD SUPPORT SECTION - NOT INTERACTIVE -->

  <!-- <div class="hidden lg:flex bg-white mb-20">
  <div class="relative max-w-7xl mx-auto">
  <div class="absolute left-4 h-full w-1/2">
  <div class="pl-6 py-6">
    <div class="max-w-3xl ml-0 flex flex-row">
      <StarIcon class="mr-2 h-5 w-5 text-red-600" aria-hidden="true" />
      <StarIcon class="mr-2 h-5 w-5 text-red-600" aria-hidden="true" />
      <StarIcon class="mr-2 h-5 w-5 text-red-600" aria-hidden="true" />
      <StarIcon class="mr-2 h-5 w-5 text-red-600" aria-hidden="true" />
      <StarIcon class="mr-2 h-5 w-5 text-red-600" aria-hidden="true" />
    </div>
    <h2 class="text-left text-3xl font-extrabold text-red-600 sm:text-4xl">
      Zákaznická podpora
    </h2>
  </div>
    <ul role="list" class="space-y-3">
      <li v-for="item in support" :key="item.id" class="relative cursor-pointer h-auto w-10/12 bg-white shadow overflow-hidden rounded-md pt-8"> -->
  <!-- Your content -->
  <!-- <div class="px-6 h-20"> -->
  <!-- <div class="relative z-10">
            <h3 class="mt-3 text-2xl font-extrabold text-red-600"> {{ item.category }} </h3>
            <p class="text-black"> {{ item.snippet }} </p>
          </div> -->
  <!-- </div>
        <div class="absolute inset-0 h-auto z-0">
          <img class="object-cover object-bottom cursor-pointer" :src="item.imagePrimaryURL" alt="">
        </div> -->
  <!-- ADD ROUTER LINK -->
  <!-- <router-link :to="item.categoryRoute">
        <div class="relative bg-gray-50 z-20">
          <div class="relative z-10 pt-1 pb-3 px-3">
            <h3 class="mt-3 text-2xl font-extrabold text-red-600"> {{ item.category }} </h3> -->
  <!-- <p class="text-black"> {{ item.snippet }} </p> -->
  <!-- </div>
        </div>
        </router-link>
      </li>
    </ul>
  </div>
  <div class="h-56 bg-indigo-600 sm:h-72 lg:absolute lg:right-0 lg:h-full lg:w-1/2">
    <img class="w-full h-full object-cover" src="@/assets/img/components/support_02-big.jpg" alt="Support team" />
  </div>
  <div class="relative max-w-7xl mx-auto px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:pt-36">
    <div class="max-w-2xl mx-auto lg:max-w-none lg:mr-0 lg:ml-auto lg:w-1/2 lg:pl-10">
      <h3 class="text-sm font-semibold text-gray-600 tracking-wide uppercase">
        Pomoc při intraorálním skenování
      </h3>
      <h2 class="mt-6 text-3xl font-extrabold text-red-600 sm:text-4xl">
        Poradentsví pro IO skenování
      </h2>
      <p class="mt-6 mb-6 pr-36 text-lg text-gray-500">
        Staňte se produktivnější a ušetřete si další návštěvy pacientů. Prostě jednou naskenujte, pošlete data a čekejte na práci.
        <br>
        <br>
        Poradíme Vám, jak můžete jednoduše zdokonalit digitální otiskování. Kvalitní digitální otisk je začátek. My jej proměníme v maximálně přesnou, funkční a estetickou práci.
      </p>
      <ul class="flex flex-col ml-4 mb-20">
        <li class="inline-flex items-center mt-4 uppercase text-sm font-bold text-gray-500">
          <FastForwardIcon class="mr-2 h-8 w-8 text-red-600" aria-hidden="true" />
          Zrychlete ošetření
          </li>
        <li class="inline-flex items-center mt-4 uppercase text-sm font-bold text-gray-500">
          <RefreshIcon class="mr-2 h-8 w-8 text-red-600" aria-hidden="true" />
          Již žádné přeskenovávání
          </li>
        <li class="inline-flex items-center mt-4 uppercase text-sm font-bold text-gray-500">
          <HeartIcon class="mr-2 h-8 w-8 text-red-600" aria-hidden="true" />
          Zubní náhrada bez kompromisů
          </li>
      </ul>
      <div class="mt-8 overflow-hidden">
        <h4 class="text-sm font-semibold text-gray-600 tracking-wide uppercase">5 kroků k lepšímu digitálnímu otisku:</h4>
      </div>
      <div class="mt-6 mb-20 rounded-md shadow">
        <router-link :to="{ name: 'DigitalniOtiskovani' }" class="flex uppercase items-left justify-center py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50">
          jak na to
        </router-link>
      </div>
    </div>
  </div>
  </div>
</div> -->

  <!-- SUPPORT DESKTOP END -->

  <!-- SUPPORT MOBILE -->
  <div class="lg:hidden">
    <div class="bg-gray-50">
      <div class="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div class="max-w-3xl mx-auto flex flex-row">
          <StarIcon class="mr-2 h-5 w-5 text-red-600" aria-hidden="true" />
          <StarIcon class="mr-2 h-5 w-5 text-red-600" aria-hidden="true" />
          <StarIcon class="mr-2 h-5 w-5 text-red-600" aria-hidden="true" />
          <StarIcon class="mr-2 h-5 w-5 text-red-600" aria-hidden="true" />
          <StarIcon class="mr-2 h-5 w-5 text-red-600" aria-hidden="true" />
        </div>
        <div class="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2
            class="text-left text-3xl font-extrabold text-red-600 sm:text-4xl"
          >
            Zákaznická podpora
          </h2>
          <dl class="mt-6 space-y-6 divide-y divide-gray-200">
            <Disclosure
              as="div"
              v-for="topic in support"
              :key="topic.question"
              class="pt-6"
              v-slot="{ open }"
            >
              <dt class="text-lg">
                <DisclosureButton
                  class="
                    text-left
                    w-full
                    flex
                    justify-between
                    items-start
                    text-gray-400
                  "
                >
                  <span class="font-medium text-gray-900">
                    {{ topic.category }}
                  </span>
                  <span class="ml-6 h-7 flex items-center">
                    <ChevronDownIcon
                      :class="[
                        open ? '-rotate-180' : 'rotate-0',
                        'h-6 w-6 transform',
                      ]"
                      aria-hidden="true"
                    />
                  </span>
                </DisclosureButton>
              </dt>
              <DisclosurePanel as="dd" class="mt-2 pr-12">
                <!-- <p class="text-base text-gray-500">
                {{ topic.content }}
              </p> -->
                <p
                  v-for="line in topic.content"
                  :key="line.id"
                  class="text-base text-gray-500"
                >
                  {{ line }}
                </p>
                <div v-if="topic.buttonPrimary" class="mt-4">
                  <h3 class="text-sm font-semibold uppercase">
                    {{ topic.buttonHeading }}
                  </h3>
                  <a
                    :href="topic.buttonPrimaryURL"
                    class="
                      flex
                      uppercase
                      items-center
                      justify-center
                      mt-4
                      px-5
                      py-3
                      border border-transparent
                      text-base
                      font-medium
                      text-white
                      bg-gray-500
                      hover:bg-red-600
                    "
                  >
                    {{ topic.buttonPrimary }}
                  </a>
                </div>
              </DisclosurePanel>
            </Disclosure>
          </dl>
        </div>
      </div>
    </div>
  </div>
  <!-- SUPPORT MOBILE END -->

  <SignupSection />
  <GalleryOverview />
  <TestimonialDouble />
</template>

<script>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue' // eslint-disable-line vue/no-unused-components
import LeadTimes from '@/components/LeadTimes.vue'
import Banner from '@/components/Banner.vue'
import GalleryOverview from '@/components/GalleryOverview.vue'
import TestimonialDouble from '@/components/TestimonialDouble.vue'
import SignupSection from '@/components/SignupSection.vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import {
  FastForwardIcon,
  HeartIcon,
  RefreshIcon,
  ChevronDownIcon,
  ExternalLinkIcon,
  CheckIcon,
  GlobeAltIcon,
  LightningBoltIcon,
  MailIcon,
  ScaleIcon,
  PhoneIcon,
} from '@heroicons/vue/outline'
import { StarIcon } from '@heroicons/vue/solid'
const features = [
  {
    name: 'Korunky a můstky',
    description: [
      'Celokeramika',
      'Zirkon',
      'Metalokeramika',
      'PMMA',
      'Kompozit',
    ],
    icon: GlobeAltIcon,
  },
  {
    name: 'Implantáty',
    description: ['Celokeramika', 'Metalokeramika', 'Zirkon', 'Kompozit'],
    icon: ScaleIcon,
  },
  {
    name: 'Ostatní',
    description: ['Nákusná dlaha', 'Fólie k bělení', 'Chránič zubů'],
    icon: LightningBoltIcon,
  },
  {
    name: 'Indikace materiálů',
    description: [
      'Usnadněte si výběr správného materiálu s naším přehledným průvodcem.',
    ],
    icon: MailIcon,
    button: 'PRŮVODCE INDIKACEMI',
    categoryRoute: 'indikace',
  },
]

const tiers = [
  {
    name: 'Standard',
    href: '/log-in',
    imageUrl: require('@/assets/img/components/scan.jpg'),
    priceMonthly: 'IO skener',
    description: 'on-line',
    features: [
      'Krátké dodací lhůty',
      'Precizní zpracování',
      'Přijímáme data v .stl/.obj',
      'Okamžitá kontrola naskenovaných dat',
    ],
    button: 'Zadat zakázku',
  },
  {
    name: 'Enterprise',
    href: '/log-in',
    hrefSecondary: 'tel:+420 725 433 894',
    imageUrl: require('@/assets/img/components/impressions.jpg'),
    priceMonthly: 'Otisky',
    description: 's dopravou',
    features: [
      'Doprava zcela ZDARMA',
      'Vyzvedneme a doručíme kamkoli v ČR/SR',
      'Snadné objednání',
    ],
    button: 'objednat svoz',
    buttonSecondary: '+420 725 433 894',
  },
]

const support = [
  {
    category: 'Výběr či indikace materiálu',
    header: 'Výběr či Indikace materiálu',
    snippet: '',
    content: [
      'Potřebujete poradit s výběrem materiálu pro plánovanou protetiku?',
      'Nejste si jisti správnou indikací pro daný případ?',
      'Neváhejte se na nás obrátit telefonicky na čísle +420 725 433 894 anebo e-mail info@eurozahn.cz',
    ],
    contentSecondary: '',
    imagePrimaryURL: require('@/assets/img/components/support_01.jpg'),
    imageSecondaryURL: require('@/assets/img/components/support_01-big.jpg'),
    tags: false,
    tagOne: null,
    tagTwo: null,
    tagThree: null,
    categoryRoute: 'indikace',
    buttonHeading: 'Usnadněte si výběr správného materiálu:',
    buttonPrimary: 'průvodce indikacemi',
    buttonPrimaryURL: 'indikace',
    buttonSecondary: null,
    buttonSecondaryURL: null,
  },
  {
    category: 'Pomoc při intraorálním skenování',
    header: 'Poradentsví pro IO skenování',
    snippet: '',
    content: [
      'Poradíme Vám, jak můžete jednoduše zdokonalit digitální otiskování.',
      'Kvalitní digitální otisk je začátek. My jej proměníme v maximálně přesnou, funkční a estetickou práci.',
    ],
    contentSecondary: '',
    imagePrimaryURL: require('@/assets/img/components/support_02.jpg'),
    imageSecondaryURL: require('@/assets/img/components/support_02-big.jpg'),
    tags: true,
    tagOne: 'ZRYCHLETE OŠETŘENÍ',
    tagTwo: 'JIŽ ŽÁDNÉ PŘESKENOVÁVÁNÍ',
    tagThree: 'ZUBNÍ NÁHRADA BEZ KOMPROMISŮ',
    categoryRoute: 'digitalni-otiskovani',
    buttonHeading: '5 kroků k lepšímu digitálnímu otisku:',
    buttonPrimary: 'jak na to',
    buttonPrimaryURL: '/digitalniOtiskovani',
    buttonSecondary: null,
    buttonSecondaryURL: null,
  },
  {
    category: 'Informace o zakázce',
    header: 'Informace o zakázce',
    snippet: '',
    content: [
      'Potřebujete se dozvědět více o Vaší zakázce?',
      'Chcete změnit barvu či sdělit technikovi více informací k práci?',
      'V první řadě využijte naši aplikaci, kde můžete rychle a jednoduše komunikovat přímo se zubním technikem či vedoucí laboratoře.',
      'Vyhovuje-li Vám telefonický kontakt, potom volejte na číslo: +420 725 433 894',
    ],
    contentSecondary: '',
    imagePrimaryURL: require('@/assets/img/components/support_03.jpg'),
    imageSecondaryURL: require('@/assets/img/components/support_03-big.jpg'),
    tags: false,
    tagOne: null,
    tagTwo: null,
    tagThree: null,
    categoryRoute: 'zakazky-informace',
    buttonHeading: null,
    buttonPrimary: null,
    buttonPrimaryURL: null,
    buttonSecondary: null,
    buttonSecondaryURL: null,
  },
  {
    category: 'Fakturace & formality',
    header: 'Fakturace & formality',
    snippet: '',
    content: [
      'Potřebujete diskutovat položky na faktuře?',
      'Máte připomínky týkající se spolupráce?',
      'Volejte +420 724 039 395 anebo pište na ondrej.kudera@eurozahn.cz',
    ],
    contentLine1: '',
    contentLine2: '',
    contentSecondary: '',
    imagePrimaryURL: require('@/assets/img/components/support_04.jpg'),
    imageSecondaryURL: require('@/assets/img/components/support_04-big.jpg'),
    tags: false,
    tagOne: null,
    tagTwo: null,
    tagThree: null,
    categoryRoute: 'fakturace',
    buttonHeading: null,
    buttonPrimary: null,
    buttonPrimaryURL: null,
    buttonSecondary: null,
    buttonSecondaryURL: null,
  },
]

export default {
  components: {
    ExternalLinkIcon,
    Banner,
    LeadTimes,
    CheckIcon,
    PhoneIcon,
    StarIcon,
    TestimonialDouble,
    GalleryOverview,
    SignupSection,
    ChevronDownIcon,
    FastForwardIcon,
    HeartIcon,
    RefreshIcon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    TabGroup, // eslint-disable-line vue/no-unused-components
    TabList, // eslint-disable-line vue/no-unused-components
    Tab, // eslint-disable-line vue/no-unused-components
    TabPanels, // eslint-disable-line vue/no-unused-components
    TabPanel, // eslint-disable-line vue/no-unused-components
  },
  setup() {
    return {
      tiers,
      features,
      support,
    }
  },
}
</script>
